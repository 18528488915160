<template>
  <div>
    <ui-component-modal
      :modalTitle="$t('Components.Vouchers.ModalEditLocation.Main_Title')"
      modalSize="large"
      :onClickCancel="onClickCancel"
      :showModal="showModal"
      :onClickSave="saveVoucher"
      :isSaving="isSaving"
      :isSavingSuccess="isSavingSuccess"
      :isSavingError="isSavingError"
      :hideFooterButtons="isSavingError || isSavingSuccess"
      @closeModal="onClickCancel"
    >
      <template v-slot:content>
        <div class="form-companies columns">
          <div class="search-add column-search column">
            <div class="add-list">
              <div
                class="row"
                v-for="(item, index) in locationsPageState.locations"
                :key="index"
              >
                <div class="item">{{ item.Name }}</div>
                <div class="button-group">
                  <button
                    @click="addLocationToVoucher(item.Id, item.Name, true)"
                    class="button is-small is-success"
                  >
                    <a>
                      <span class="icon">
                        <font-awesome-icon
                          :icon="['fas', 'plus']"
                          style="color: white"
                        />
                      </span>
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="menu-options column">
            <div class="selected-options-wrapper">
              <div class="title is-3">
                {{ $t('Components.Vouchers.ModalEditLocation.Sub_Title') }}
              </div>
              <div class="list">
                <div
                  class="row"
                  v-for="(location, index) in voucher.Locations"
                  :key="index"
                >
                  <div class="name">{{ location.LocationName }}</div>
                  <label class="checkbox" v-if="channelId === 0">
                    <input type="checkbox" v-model="location.LocationCreated" />
                    {{
                      $t(
                        'Components.Vouchers.ModalEditLocation.Checkbox_Label_Can-edit-voucher'
                      )
                    }}
                  </label>

                  <a @click="deleteOption(index)">
                    <span class="icon">
                      <font-awesome-icon
                        :icon="['fas', 'trash-alt']"
                        style="color: red"
                      />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ui-component-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapMutations, mapState } from 'vuex'
import locationProvider from '@/providers/location'
import voucherProvider from '@/providers/voucher'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      channelId: this.$route.params.channelId
        ? Number(this.$route.params.channelId)
        : 0,
      locationId: this.$route.params.locationId
        ? Number(this.$route.params.locationId)
        : 0,
      isSaving: false,
      isSavingError: false,
      isSavingSuccess: false,
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
    ...mapState('locationStore', ['locationsPageState']),
    ...mapState('voucherStore', ['voucher']),
  },

  created() {
    if (this.locationId > 0) {
      this.channelId = 0
    }

    if (
      !this.locationsPageState ||
      !this.locationsPageState.locations ||
      this.locationsPageState.locations.length === 0
    ) {
      this.getLocations()
    }
  },

  methods: {
    ...mapMutations('voucherStore', ['setVoucher']),
    ...mapMutations('locationStore', ['setLocationsPageState']),

    getLocations() {
      let self = this

      if (!self.reachedEnd) {
        self.isSearching = true

        let locationsPageState = JSON.parse(
          JSON.stringify(self.locationsPageState)
        )
        locationsPageState.channelId = self.channel.Id

        locationProvider.methods
          .getLocations(locationsPageState.channelId, '', 1, 1000)
          .then((response) => {
            self.isSearching = false

            let locations = []

            if (response.data.MorePages === 0) {
              self.reachedEnd = true
            }

            if (self.page > 1) {
              locations = self.locationsPageState.locations
            }

            for (let i = 0; i < response.data.Results.length; i++) {
              locations.push(response.data.Results[i])
            }

            locationsPageState.locations = locations
            self.setLocationsPageState(locationsPageState)
          })
      }
    },

    addLocationToVoucher(Id, Name, Edit) {
      let location = { LocationId: Id, LocationName: Name, Edit }
      this.voucher.Locations.push(location)
    },

    deleteOption(index) {
      Vue.delete(this.voucher.Locations, index)
    },

    saveVoucher() {
      if (!this.isSaving) {
        this.isSaving = true

        voucherProvider.methods
          .saveVoucher(this.voucher)
          .then((response) => {
            if (response.status === 200) {
              this.setVoucher(response.data)
              this.isSavingSuccess = true

              setTimeout(() => {
                this.$emit('voucherEdited', response.data)
                this.onClickCancel()
              }, 1500)
            }
          })
          .catch((error) => {
            //console.log(error.response)
            this.isSavingError = true
          })
          .finally(() => {
            this.isSaving = false
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/template';
.form-companies {
  .search-add {
    display: flex;
    flex-direction: column;
    &.column-search {
      width: 33%;
    }
    .add-list {
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      border: 1px solid $grey-lighter;
      border-radius: 6px;
      height: 300px;
      .row {
        position: relative;
        display: flex;
        align-items: center;
        border-bottom: 1px dotted $grey-lighter;
        padding: 10px;
        .item {
          width: 90%;
          flex-wrap: wrap;
        }
        .button-group {
          position: absolute;
          right: 10px;
          width: 21px;
          height: 21px;
          .button {
            padding: 0;
            width: 100%;
            height: 100%;
            font-size: 12px;
          }
        }
      }
    }
  }
  .selected-options-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    .title {
      flex-grow: 0;
    }
    .list {
      flex-grow: 1;
      overflow-y: scroll;
      .row {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px dotted $grey-lighter;
        padding: 10px;
        .name {
        }
      }
    }
  }
}
</style>
